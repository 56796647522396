
export interface Theme {
    name: string;
    properties: any;
}

export const light: Theme = {
    name: 'light',
    properties: {
      '--background-default': '#FEE9DF',
      '--color-primary': '#F56B2A',
      '--color-default': '#333333',
      '--background-active': '#333333',
      '--color-active': '#FEE9DF',
      '--svg-primary': '#333333',
      '--svg-secondary': '#FFFFFF'
    }
};


export const darkblue: Theme = {
    name: 'darkblue',
    properties: {
      '--background-default': '#002E5B',
      '--color-primary': '#F56B2A',
      '--color-default': '#FFFFFF',
      '--background-active': '#F56B2A',
      '--color-active': '#FFFFFF',
      '--svg-primary': '#FFFFFF',
      '--svg-secondary': '#002E5B'
    }
};

export const lightblue: Theme = {
  name: 'lightblue',
  properties: {
    '--background-default': '#2E4E74',
    '--color-primary': '#F56B2A',
    '--color-default': '#FFFFFF',
    '--background-active': '#F56B2A',
    '--color-active': '#FFFFFF',
    '--svg-primary': '#FFFFFF',
    '--svg-secondary': '#2E4E74'
  }
};
