import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, map, switchMap, catchError, retry } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UrlService } from '../services/url.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private notification: NzNotificationService, private url: UrlService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers;
        if (this.authService.isLoggedIn()) {
            const token = this.authService.getToken();
            headers = headers.set('Authorization', `Bearer ${token}`);
        }
        if (req.url.includes('batch/add')) {
            headers = headers.delete('content-type');
        }
        else {
           headers = headers.set('Content-Type', 'application/json');
        }
        const authReq = req.clone({ headers });
        this.toggleAllButtonsDisable(true);
        return next.handle(authReq).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                console.log(event.body);
                this.toggleAllButtonsDisableToenable(false);
                this.toggleAllButtonsDisable(false);
                if (event.body.status === 'success') {
                    // this.notification.success('Successful', event.body.message);
                } else {
                    this.notification.error('Error', event.body.message);
                }
            }
            return event;
        }), catchError((error: HttpErrorResponse) => {
            const errorStatus = error.status;
            this.toggleAllButtonsDisableToenable(false);
            this.toggleAllButtonsDisable(false);
            console.log(error);
            if (errorStatus === 401) {
                // const currentUrl = this.url.getCurrentUrl();
                // this.authService.toggleModal(true);
                this.authService.logOut().then(() => {
                  location.replace(`https://princepsfinance.sharepoint.com/sites/the-hub`)
                })
            } else if (errorStatus === 500) {
                this.notification.error('Server Error', 'Something went wrong! This is likely from IT, we will fix it soon. Please try again later');
            } else {
                this.notification.error('Error', error.error.message || error.message);
            }
            return throwError(error);
        }));
    }

    private toggleAllButtonsDisable(value) {
        const buttons: any = document.querySelectorAll('button:not(:disabled)');
        for (const button of buttons) {
          button.disabled = value;
        }
    }

    private toggleAllButtonsDisableToenable(value) {  // created by solomon to enable
        // const buttons: any = document.getElementsByTagName('button:not([data-disable])');
        const buttons: any = document.querySelectorAll('button:disabled');
        for (const button of buttons) {
          button.disabled = value;
        }
    }
}
