import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../core/services/auth.service';
import * as bootstrap from 'bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('authModal') authModal: any;
  authRequestModal: bootstrap.Modal | undefined;
  user: any;
  loginForm: FormGroup;
  isLoggingIn: boolean;
  isVisible = false;
  formType = 1;
  hide = true;
  isAlertVisible = false;
  isCollapsed = false;
  constructor(private authservice: AuthService, private modal: NzModalService, private fb: FormBuilder) {
    const user = authservice.getUser();
    this.user = JSON.parse(user);

    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.runInterval();
  }

  ngOnDestroy() {
    this.closeModal();
  }

  runInterval() {
   setTimeout(() => {
    this.modal.info({
      nzTitle: 'Call Log Upload Notification',
      nzContent: '<p>Please upload your call log now for your KPI Report. Please note that failure to adhere to this have a severe negative impact on your KPI.</p>',
      nzClosable: false,
      nzOnOk: () => {
        this.runInterval()
      },
    });
   }, 3600000);
  }

  ngAfterViewInit(): void {
    // this.authRequestModal = new bootstrap.Modal(this.authModal.nativeElement, {});
    this.authservice.getAuthModalValue().subscribe(value => {
      const user = this.authservice.getUser();
      const parsedUser = JSON.parse(user);
      if (value === true) {
        // this.authRequestModal?.show();
        // this.isVisible = true;
        const email = parsedUser?.email
        console.log(email)
        if (email) {
          this.loginForm.patchValue({ email })
          this.formType = 1
        } else {
          this.formType = 2
        }
        this.isVisible = true;
      }
    });
  }

  openModal() {
    this.authRequestModal = new bootstrap.Modal(this.authModal.nativeElement);
    this.authRequestModal.show();
  }

  async closeModal() {
    // const closeModal = await bootstrap.Modal.getInstance(this.authModal.nativeElement);
    // closeModal?.hide();
    this.isVisible = false;
  }

  resetAuthModal() {
    this.loginForm.reset();
  }

  changeFormType(value: number) {
    this.formType = value;
    this.resetAuthModal()
  }


  get formControls() {
    return this.loginForm.controls;
  }

  handleSubmit() {
    Object.keys(this.formControls).forEach(key => {
      this.formControls[key].markAsDirty();
      this.formControls[key].updateValueAndValidity();
    });
    console.log(this.loginForm.value)
    if (this.loginForm.valid) {
      this.isLoggingIn = true;
      this.loginForm.disable();
      this.authservice.login(this.loginForm.value).subscribe((data: any) => {
        this.isLoggingIn = false;
        this.loginForm.enable();
        if (data.status === 'success') {
          this.authservice.storeToken(data.access_token);
          this.authservice.storeUser(data.user);
          this.loginForm.reset();
          this.closeModal().then(() => location.reload());
          // setTimeout(() => {
          // ;
          // });
        }
      }, (error) => {
        this.loginForm.enable();
        this.isLoggingIn = false;
        if (error instanceof HttpErrorResponse) {
          if (error.status === 400) {
            this.loginForm.setErrors({
              badRequest: error.error.message
            });
          } else if (error.status === 401) {
            this.loginForm.setErrors({
              unAuthorized: error.error.message
            });
          }
        }
      }, () => {
        this.loginForm.enable();
        this.isLoggingIn = false;
      });
    }
  }

  // openAlertModal() {

  // }
}
