import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ActivateRouteGuard implements CanActivate {
  user: any;
  constructor(private auth: AuthService, private router: Router) {
    const user = auth.getUser();
    this.user = !!user && JSON.parse(user);
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const params = next.data.activateParams;
    return this.checkIfAllowed(params);
  }

  checkIfAllowed(param: { permission: string, reroute: string }): boolean {
    const permissions: any[] = this.user.permissions;
    if (!!permissions) {
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < permissions.length; index++) {
        const permission = permissions[index];
        if (permission.path === param.permission) {
          return true;
        }
      }
      this.router.navigate([param.reroute]);
      return false;
    } else {
      this.router.navigate([param.reroute]);
      return false;
    }
  }
}
