import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { light } from './theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'marketing-new';

  constructor(themeService: ThemeService,) {
    const theme = localStorage.getItem('cw-theme');
    if (theme) {
      themeService.setActiveTheme(JSON.parse(theme));
    } else {
      themeService.setActiveTheme(light);
    }
  }

}
